<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein/abteilungen" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-account-supervisor-circle</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          {{ abteilung.name }}
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col cols="12" md="10" class="py-0">
              <v-text-field
                filled
                label="Name"
                v-model="abteilung.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10" class="py-0">
              <v-btn
                :color="success ? 'success' : template.colors.primary"
                block
                large
                :loading="creating"
                @click="save()"
              >
                <v-scroll-x-transition hide-on-leave>
                  <div v-if="success">
                    <v-icon color="white" class="mr-2">mdi-check-bold</v-icon>
                    GESPEICHERT
                  </div>
                </v-scroll-x-transition>
                <v-scroll-x-reverse-transition hide-on-leave>
                  <span v-if="!success">SPEICHERN</span>
                </v-scroll-x-reverse-transition>
              </v-btn>
            </v-col>
            <v-col cols="12" md="10" class="py-0 mt-3">
              <v-divider class="my-5"></v-divider>
              <v-btn
                :color="success ? 'success' : template.colors.primary"
                block
                outlined
                large
                :loading="creating"
                @click="deleting ? deleteAbteilung() : (deleting = true)"
              >
                {{ deleting ? 'LÖSCHEN BESTÄTIGEN' : 'ABTEILUNG LÖSCHEN' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'Abteilung-bearbeiten',
  data() {
    return {
      abteilung: {
        name: '',
      },
      creating: false,
      generating: false,
      success: false,
      deleting: false,
      linkok: '',
      linkexist: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.template.var.deactivate_abteilung) {
      router.push('/verein')
    }
    if (this.$route.params.abteilung) {
      if (this.user.data.abteilungen) {
        this.abteilung = this.user.data.abteilungen[
          this.$route.params.abteilung
        ]
      }
    } else {
      router.push('/verein/abteilungen')
    }
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    save() {
      this.creating = true
      var neue_abteilungen = this.user.data.abteilungen
      neue_abteilungen[this.$route.params.abteilung] = this.abteilung
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          abteilungen: neue_abteilungen,
        })
        .then(() => {
          this.creating = false
          this.success = true
          setTimeout(() => {
            this.success = false
          }, 4000)
        })
    },
    deleteAbteilung() {
      var i
      var neue_abteilungen = []
      for (i = 0; i < this.user.data.abteilungen.length; i++) {
        if (this.$route.params.abteilung != i) {
          neue_abteilungen.push(this.user.data.abteilungen[i])
        }
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          abteilungen: neue_abteilungen,
        })
        .then(() => {
          router.push('/verein/abteilungen')
        })
    },
  },
}
</script>
